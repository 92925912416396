
export var customScript = function () {
    // $('.moreless-button').click(function() {
    //     $('.moretext').slideToggle();
    //     if ($('.moreless-button').text() == "Read Less") {
    //       $(this).text("Read More")
    //     } else {
    //       $(this).text("Read Less")
    //     }
    //   });

     
}
export var navScript = function () {
$(window).scroll(function(){
    if ($(this).scrollTop() > 50) {
       $('.navbar').addClass('fixed');
    } else {
       $('.navbar').removeClass('fixed');
    }
});
}