import { Injectable } from '@angular/core';
import { UrlService} from '../../services/url/url.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient,
    private urlService: UrlService) { }

  getBookingAll(body){
    return this.http.post(this.urlService.getBookingAll,body);
  }

  getCustomerNotification(){
    return this.http.get(this.urlService.getCustomerNotification);
  }
}
