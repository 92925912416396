import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Injectable({
  providedIn: 'root'
})
export class GetInterceptorService {

  constructor(private router: Router,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private translate: TranslateService) {
    }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      this.ngxUiLoaderService.stop();
      if (event instanceof HttpResponse) {
      }
    }, (error: any) => {
      this.ngxUiLoaderService.stop();
      if(error['error']) {
        if (error['error']['code'] === 401) {
           this.toastr.error(this.translate.instant("YOUR SESSION IS EXPIRED, PLEASE LOG IN."));
          localStorage.clear();
          return this.router.navigate(['login','phone']);
        }else{
          if(error['error']){
            this.toastr.error(error['error']['message'])
          }
        }
      }
    }));
  }

}
