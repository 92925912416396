
<ng-container *ngIf="isShow ==true">
    <ng-container *ngIf="homeStatus">
        <app-header></app-header>
    </ng-container>
    <ng-container *ngIf="!homeStatus">
        <app-header1></app-header1>
    </ng-container>
</ng-container>
<ng-container *ngIf="showLogo && isShow == false && homeStatus==false">
    <app-header-logo></app-header-logo>
</ng-container>

<router-outlet (activate)="onActivate($event)"></router-outlet>
<ng-container *ngIf="footerStatus">
    <app-footer></app-footer>
</ng-container>

<!-- <div class="chat_bx">
    <figure><img src="assets/images/chat_main_icon.svg" alt="Icon"></figure>
</div> -->