import { Injectable } from '@angular/core';
import { UrlService } from '../../services/url/url.service';
import * as socketIo from 'socket.io-client';
import { BehaviorSubject,Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationSocketService {
  
  socket: any;
  user:any;
  notificationObserve: any;
  messageStatus : BehaviorSubject<any> = new BehaviorSubject('Worked');
  // messageStatus: BehaviorSubject<any>;

  constructor(private urlService: UrlService) {
    this.socket = socketIo.connect(this.urlService.socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 3000,
      reconnectionAttempts: Infinity
    })
    if (localStorage.getItem('jmc_web')) {
      this.user = JSON.parse(localStorage.getItem('jmc_web'));
      this.initSocket();
    }
  }

  initSocket() {
    if (localStorage.getItem('jmc_web')) {
      this.user = JSON.parse(localStorage.getItem('jmc_web'));
      this.socket = socketIo.connect(this.urlService.socketUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        transports: ['websocket', 'polling', 'flashsocket'],
        query: {
          token: this.user['accessToken']
        }
      });
    }
  
    this.socket.on('connect', (res: any) => {
      var data = this.user['user']['_id'];
      this.socket.on(data, message => {
        this.notificationObserve.next(message)
      });
    });
    this.socket.on('disconnect', (message: any) => {
      console.log('debug disconnect', message);
    });
  }

  getNotificationStatus(){
    return new Observable<any>((observer) => {
      this.notificationObserve = observer;
    });
  }

  // getOption(): Observable<any> {
  //   return this.messageStatus.asObservable()
  // }

  setOption(value: any){
    this.messageStatus.next(value);
  }

  disconnectSocket() {
    if(this.socket.connected){
      this.socket.disconnect();
    }
  }

}
