import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss']
})
export class HeaderLogoComponent implements OnInit {

  constructor( public router: Router) { }

  ngOnInit(): void {
  }

  goToHomepage(){
    console.log("sdjjsdj");
    
    if(localStorage.getItem('jmc_web')){
      let user = JSON.parse(localStorage.getItem('jmc_web'))['user'];
      if(user){
        this.router.navigate(['/bookingPage']);
      }else{
        this.router.navigate([''])
      }
    }else{
      this.router.navigate(['/login/phone'])
    }
  }
}
