import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService} from '../../services/common.service';
import { NotificationSocketService } from '../../services/socket/notification-socket.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

 
  constructor(public dialog: MatDialogRef<ConfirmationComponent>,
  private toastr: ToastrService,
  public translate: TranslateService,
  private router:Router,
  private commonService: CommonService, private socket : NotificationSocketService) { }

  ngOnInit(): void {
  }

  onSubmit(){
    let data;
    let lng;
    if(localStorage.getItem('jmc_rememberMe')){
      data = localStorage.getItem('jmc_rememberMe');
    }
    if(localStorage.getItem('jmc_lang')){
      lng = localStorage.getItem('jmc_lang');
    }
    
    this.commonService.logout().subscribe(res=>{
      if(res['code']==200){
        this.toastr.success(res['message']);
        this.socket.disconnectSocket();
        localStorage.clear();
        if(data){
          localStorage.setItem('jmc_rememberMe', data);
        }
        if(lng){
          localStorage.setItem('jmc_lang', lng);
        }
      }
    })
    this.router.navigate(['login','phone']);
    this.dialog.close();
  }

  onCancel(){
    this.dialog.close();
  } 


}
