<div class="lng_dv"><p (click)="onSupport()">{{'SUPPORT'|translate}}</p> 
    <select [(ngModel)]="currentLang" class="lng_dv_inn" (ngModelChange)="onLanguageTranslate()">
        <option *ngFor="let lang of translate.getLangs()" [value]="lang">
            <ng-container *ngFor="let customName of langArray">
                <ng-container *ngIf="customName.value == lang">
                    {{customName.name}}
                </ng-container>
            </ng-container>
        </option>
    </select>
</div>
<!-- <figure><img src="assets/images/login_img.png" alt="Image"></figure> -->