import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject,Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";
import { UrlService } from '../services/url/url.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  public $is_profile_changed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public $is_profile_changed_main_header: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public $is_currentLang_changed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public currentLang : BehaviorSubject<any> = new BehaviorSubject<any>('');
  public lang: any;
  serviceType: any;
  displayToken: any;
  // socket;
  notificationList: any=[];
  constructor(private toastrService: ToastrService,
  private httpClient: HttpClient,
  private urlService: UrlService,
  private translateService: TranslateService) { 
    if(localStorage.getItem('jmc_lang')){
      this.setLang();
    }else{
      localStorage.setItem("jmc_lang", JSON.stringify('en'))
      this.setLang();
    }
  }

  getNationalityList(){
    return this.httpClient
      .get<Response>("assets/json/nationality.json")
      .pipe(map(response => response));
  }

  validateImageFile(fileData) {
    if (fileData.type == 'image/jpeg' || fileData.type == 'image/png' || fileData.type == 'image/jpg' || fileData.type == 'image/svg' || fileData.type == 'image/webp' || fileData.type == 'image/gif') {
      return true;
    } else {
      this.toastrService.error('Only Image file is accepted!')
      return false;
    }
  }

  profileChanged(event){
    this.$is_profile_changed.next(event);
  }

  profileChangedMainHeader(event){
    this.$is_profile_changed_main_header.next(event);
  }

  currentLangChange(event){
    this.$is_currentLang_changed.next(event);
  }

  getLang(): Observable<any> {
    return this.currentLang.asObservable();
  }

  logout(){
    return this.httpClient.post(this.urlService.logout, {});
  }

  setLang() {
    if(localStorage.getItem('jmc_lang')){
      let lng = JSON.parse(localStorage.getItem('jmc_lang'));
      console.log(lng)
      this.currentLang.next(lng);
      this.translateService.use(lng);
      if(localStorage.getItem('jmc_web')){
        let body={
          lang: lng
        }
        this.updateLang(body).subscribe(res=>{
        })
      }
      if(lng == 'en'){
        document.body.className += ' ltr';
        if(document.body.classList.contains('rtl')){
          document.body.classList.remove('rtl');
        }
      }
      if(lng == 'ar'){
        document.body.className += ' rtl';
        if(document.body.classList.contains('ltr')){
          document.body.classList.remove('ltr');
        }
      }
    }
  }

  updateLang(body){
    return this.httpClient.put(this.urlService.updateLang, body);
  }

}
