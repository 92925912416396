<div class="boking-flifgt">
    <h3>Choose pick up window</h3>
    <h5>Service Date & Time:</h5>
    <ng-container *ngIf="data && data.length>0; else noslot">
        <form>
            <mat-radio-group  aria-labelledby="example-radio-group-label" name="radio"
                class="example-radio-group"
                [(ngModel)]="selectedSlot">
                <mat-radio-button *ngFor="let slot of data" [value]="slot._id" class="example-radio-button">
                    {{slot['serviceDate'] | date:'fullDate'}} - {{slot['startTime']}}  {{slot['endTime']}}
               </mat-radio-button>
            </mat-radio-group>
        </form>
    </ng-container>
    <ng-template #noslot>
       <p class="sor">Sorry no slots are available at the moment, please continue the reservation without payment and we will contact you</p>
    </ng-template>
    <button (click)="onSubmit()" class="btn btn-primary w-100 mb-1">Continue Booking</button>
    <button (click)="onCancel()" class="btn btn-light w-100">Cancel</button>
</div>
