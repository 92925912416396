import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService} from '../../services/url/url.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) { }

  getAirportAirline(){
    return this.http.get(this.urlService.airportAirline);
  }

  getServiceSlot(body){
    return this.http.post(this.urlService.serviceSlot, body);
  }

  uploadLuggaage(body){
    return this.http.post(this.urlService.uploadLuggaage, body);
  }

  getBookingCost(body){
    return this.http.post(this.urlService.getBookingCost, body);
  }

  getDiscounts(){
    return this.http.get(this.urlService.getDiscounts);
  }

  createBooking(body){
    return this.http.post(this.urlService.createBooking, body);
  }

  paymentToken(body){
    return this.http.post(this.urlService.paymentToken, body);
  }

  pendingPaymentToken(body){
    return this.http.post(this.urlService.pendingPaymentToken, body);
  }

  getBooking(){
    return this.http.get(this.urlService.getBooking);
  }

  addReview(body){
    return this.http.post(this.urlService.addReview, body);
  }

  getRedeem(){
    return this.http.get(this.urlService.getRedeem);
  }


  getBookingByCart(orderId){
    return this.http.get(this.urlService.getBookingByCart+'?orderId='+orderId);
  }


  getAdditional(){
    return this.http.get(this.urlService.getAdditional);
  }

  getCms(){
    return this.http.get(this.urlService.cms);
  }

  cancelBooking(body){
    return this.http.post(this.urlService.cancelBooking,body);
  }

}

