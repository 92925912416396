<div class="boking-flifgt terms-ppup">
    <h3>{{'TERMS & CONDITIONS'|translate}}</h3>
    <p class="text" *ngIf="termsData" [innerHTML]="termsData"></p>
    <div class="terms_pop_cehckmark">
        <mat-checkbox class="example-margin" [(ngModel)]="selectTerms" [ngModelOptions]="{standalone: true}">{{'I AGREE TO THE SERVICE TERMS AND CONDITIONS OF CARRIAGE'|translate}}</mat-checkbox>
    </div>
    <div class="btn-outer">
        <button (click)="onSubmit()" class="bttn bttn_theme w-100">{{'OK'|translate}}</button>
    </div>
</div>

