import { Injectable } from '@angular/core';
import { MatDialogRef,MatDialog } from '@angular/material/dialog';
import { BookingServiceComponent } from '../../modal/booking-service/booking-service.component';
import { TermsConditionComponent } from '../../modal/terms-condition/terms-condition.component';
import { ConfirmationComponent } from '../../modal/confirmation/confirmation.component'
import { JmcPointsComponent } from '../../modal/jmc-points/jmc-points.component';
import { CancelBookingComponent } from '../../modal/cancel-booking/cancel-booking.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(public dialog: MatDialog) { }

  showBookingServices(data){
		let dialogRef : MatDialogRef<BookingServiceComponent>;
    dialogRef = this.dialog.open(BookingServiceComponent);
    dialogRef.componentInstance.data = data;
		return dialogRef.afterClosed();
  }

  showTermsCondition(){
		let dialogRef : MatDialogRef<TermsConditionComponent>;
    dialogRef = this.dialog.open(TermsConditionComponent);
		return dialogRef.afterClosed();
  }

  showConfirmation(){
		let dialogRef : MatDialogRef<ConfirmationComponent>;
    dialogRef = this.dialog.open(ConfirmationComponent);
		return dialogRef.afterClosed();
  }

  showJMCpoints(data){
		let dialogRef : MatDialogRef<JmcPointsComponent>;
    dialogRef = this.dialog.open(JmcPointsComponent);
    dialogRef.componentInstance.data = data;
		return dialogRef.afterClosed();
  }

  cancelBooking(){
		let dialogRef : MatDialogRef<CancelBookingComponent>;
    dialogRef = this.dialog.open(CancelBookingComponent);
		return dialogRef.afterClosed();
  }
}
