import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import { BookingService} from 'src/app/pages/booking/booking.service';
@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  selectTerms: boolean = true;
  termsData: any;

  constructor(public dialog: MatDialogRef<TermsConditionComponent>,
    public translate: TranslateService,
    private bookingService: BookingService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getCms();
  }

  onSubmit(){
    this.dialog.close(this.selectTerms);
  }

  getCms(){
    this.bookingService.getCms().subscribe(res=>{
      if(res['code']==200){
        this.termsData= res['data']['terms'];
      }
    })
  }
}
