<footer>
    <div class="conta_iner">
        <div class="fotr_mn d-flex d-flex">
            <div class="ftr_col ftr_fstcl">
                <figure (click)="goToHomepage()"><img src="assets/images/colored_logo.svg" alt="Image"></figure>
                <p>{{'8512 Al Hamra Umm Al Jud Dist, Makkah 24331'|translate}} <br> {{'KINGDOM OF SAUDI ARABIA'|translate}}</p>
                <ul>
                    <li><h4>{{'EMAIL'|translate}}</h4> <a href="mailto:support@jed-sa.com">{{'SUPPORT@JED-SA.COM'|translate}}</a></li>
                    <li><h4>{{'CALL US NOW'|translate}}</h4> <a href="tel:920012650">920012650</a></li>
                </ul>
            </div>
            <div class="ftr_col ftr_scdcl">
                <h3>{{'ABOUT JMC'|translate}}</h3>
                <ul>
                    <li><a (click)="goTocontactUs()">{{'Get In touch'|translate}}</a></li>
                    <li><a>{{'OUR PARTNERS'|translate}}</a></li>
                </ul>
            </div>
            <div class="ftr_col ftr_thrcl">
                <h3>{{'OUR SERVICES'|translate}}</h3>
                <ul>
                    <li><a href="https://jed-sa.com/price/">{{'SERVICE PRICES'|translate}}</a></li>
                    <li><a href="https://jed-sa.com/termsconditions/">{{'CONDITIONS OF CARRIAGE'|translate}}</a></li>
                    <li><a href="https://jed-sa.com/termsconditions/">{{'TERMS OF SERVICES'|translate}}</a></li>
                </ul>

            </div>
        </div>
    </div>
</footer>
<div class="btm_ftr">
    <div class="conta_iner">
        <div class="btm_ftrmn d-flex">
            <div class="btmftr_lft d-flex">
                <p>{{'© 2021 JMC. ALL RIGHTS RESERVED'|translate}}</p>
                <ul class="d-flex">
                    <li><a href="https://jed-sa.com/privacy-policy/">{{'PRIVACY POLICY'|translate}}</a></li>
                </ul>
            </div>
            <div class="btmftr_rgt d-flex">
                <p>{{'FOLLOW US ON'|translate}}</p>
                <ul class="d-flex">
                    <li><a href="https://www.facebook.com/JMC-109097981240887/"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="https://twitter.com/JMC_2021"><i class="fa fa-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/jmc_ksa2021/?hl=en"><i class="fa fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>





