<section class="all_sec cnfrm_bk_sc">
    <div class="conta_iner">
        <div class="pdng_bok_otr">
            <figure><img src="assets/images/colored_logo.svg" alt="Image"></figure>
            <h3 [ngClass]="successStatus == true ? 'succes' : 'decline'"
            > {{message}}</h3>
        </div>
    </div>
</section>




