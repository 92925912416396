<nav class="navbar navbar-expand-md bg-dark navbar-dark">
    <div class="conta_iner">
        <div class="mn_mnu d-flex">
            <div class="lft_mnu">
                <!-- Brand -->
                <a class="navbar-brand"  (click)="goToHomepage()"><img src="assets/images/colored_logo.svg" alt="Image"></a>

                <!-- Toggler/collapsibe Button -->
                <div class="lftmnu_innr">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <ul>
                        <li class="lng_inn_dv">
                            <div class="lng_mnu">
                                <select [(ngModel)]="currentLang" class="lng_dv_inn" (ngModelChange)="onLanguageTranslate()">
                                    <option *ngFor="let lang of translate.getLangs()" [value]="lang">
                                        <ng-container *ngFor="let customName of langArray">
                                            <ng-container *ngIf="customName.value == lang">
                                                {{customName.name}}
                                            </ng-container>
                                        </ng-container>
                                    </option>
                                </select>                           
                            </div>
                        </li>
                        <li class="dropdown pfl_dv"  *ngIf="user && user['firstName'] !=''">
                            <a type="button" class="profile_dv dropdown-toggle" data-toggle="dropdown">
                                <span style="width: 45px;height: 45px;"><img [src]="user && user['profilePic'] ? _urlService.imageUrl + user.profilePic : 'assets/images/user_pic.png'" alt="User Pic"></span>
                            </a>
                            <div class="dropdown-menu">
                                <ul>
                                    <li><a class="dropdown-item" (click)="goToprofileMainPage()">{{'PROFILE'|translate}}</a></li>
                                    <li><a class="dropdown-item" (click)="onChangePassowrd()">{{'CHANGE PASSWORD'|translate}}</a></li>
                                    <li><a class="dropdown-item" (click)="onNotification()">{{'NOTIFICATION'|translate}}</a></li>
                                    <li><a class="dropdown-item" (click)="goTosettingPage()">{{'SETTINGS'|translate}}</a></li>
                                    <li class="logged_out"><a class="dropdown-item" (click)="onSignOut()">{{'SIGN OUT'|translate}}</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Navbar links -->
            <div class="rgt_mnu">
                <div class="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" (click)="goToaboutUs()">{{'ABOUT JMC'|translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="goToourServices()">{{'OUR SERVICES'|translate}} </a>
                        </li>
                        <li class="nav-item" *ngIf="!user || (user && user['firstName'] =='')">
                            <a class="nav-link" (click)="goTosignup()">{{'SIGN UP'|translate}}</a>
                        </li>
                        <li class="nav-item"  *ngIf="!user || (user && user['firstName'] =='')">
                            <a class="nav-link" (click)="goToLogin()">{{'LOGIN'|translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" (click)="onSupport()"> {{'SUPPORT'|translate}}</a>
                        </li>
                        <li class="lng_inn_dv">
                            <div class="lng_mnu">
                                <select [(ngModel)]="currentLang" class="lng_dv_inn" (ngModelChange)="onLanguageTranslate()">
                                    <option *ngFor="let lang of translate.getLangs()" [value]="lang">
                                        <ng-container *ngFor="let customName of langArray">
                                            <ng-container *ngIf="customName.value == lang">
                                                {{customName.name}}
                                            </ng-container>
                                        </ng-container>
                                    </option>
                                </select>                           
                            </div>
                        </li>
                        <li class="dropdown pfl_dv"  *ngIf="user && user['firstName']!=''">
                            <a type="button" class="profile_dv dropdown-toggle" data-toggle="dropdown">
                                <span style="width: 45px;height: 45px;"><img [src]="user && user['profilePic'] ? _urlService.imageUrl + user.profilePic : 'assets/images/user_pic.png'" alt="User Pic"></span>
                            </a>
                            <div class="dropdown-menu">
                                <ul>
                                    <li><a class="dropdown-item" (click)="goToprofileMainPage()">{{'PROFILE'|translate}}</a></li>
                                    <li><a class="dropdown-item" (click)="onChangePassowrd()">{{'CHANGE PASSWORD'|translate}}</a></li>
                                    <li><a class="dropdown-item" (click)="onNotification()">{{'NOTIFICATION'|translate}}</a></li>
                                    <li><a class="dropdown-item" (click)="goTosettingPage()">{{'SETTINGS'|translate}}</a></li>
                                    <li class="logged_out"><a class="dropdown-item" (click)="onSignOut()">{{'SIGN OUT'|translate}}</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- <div class="lng_mnu">
                    <select name="lang" class="lng_dv_inn"><option value="En">En</option><option value="Ar">Ar</option></select>
                </div> -->
            </div>
        </div>
    </div>
</nav>
