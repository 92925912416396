import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service.js';
import { UrlService } from 'src/app/services/url/url.service.js';
import * as js from'../../../assets/js/custom.js';
import { ModalService} from '../../services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.scss']
})
export class Header1Component implements OnInit {
  
  currentLang:any;
  logedInUser: any;
  langArray = [
    {
      name: "EN",
      value: "en"
    }, {
      name: "AR",
      value: "ar"
    }
  ]

  constructor(
    public router: Router,
    public translate: TranslateService,
    private readonly _commonService: CommonService,
    private modalService: ModalService,
    public readonly _urlService: UrlService) { 
      if(localStorage.getItem('jmc_web')){
        this.logedInUser = JSON.parse(localStorage.getItem('jmc_web'))['user'];
      }
      this._commonService.$is_profile_changed.subscribe(response => {
        if(response){
          this.logedInUser = JSON.parse(localStorage.getItem('jmc_web'))['user'];
        }
      })
    }

  ngOnInit(): void {
    if(localStorage.getItem('jmc_lang')){
      this.currentLang = JSON.parse(localStorage.getItem('jmc_lang'));
    }else{
      this.currentLang = 'en';
      localStorage.setItem("jmc_lang", JSON.stringify(this.currentLang));
      this._commonService.setLang();
    }

  }

  goToHomepage(){    
    if(localStorage.getItem('jmc_web')){
      let user = JSON.parse(localStorage.getItem('jmc_web'))['user'];
      if(user){
        this.router.navigate(['/bookingPage']);
      }else{
        this.router.navigate([''])
      }
    }else{
      this.router.navigate(['/login/phone'])
    }
  }
  goTocardDetails(){
    this.router.navigate(['cardDetails'])
  }
  goTojmcPoints(){
    this.router.navigate(['points'])
  }
  goToserviceType(){
    this.router.navigate(['serviceType'])
  }
  goTofollowUpBooking(){
    this.router.navigate(['followUpBooking'])
  }
  goTobookingHistory(){
    this.router.navigate(['bookings'])
  }
  goTobookingHistoryDetial(){
    this.router.navigate(['bookingHistoryDetial'])
  }
  goToprofileMainPage(){
    this.router.navigate(['profileMainPage'])
  }
  goTodiscountVoucher(){
    this.router.navigate(['/voucher'],{ queryParams: { type: 'list'}})
  }
  goTotermConditions(){
    this.router.navigate(['termConditions'])
  }
  goToprivacyPolicy(){
    this.router.navigate(['privacyPolicy'])
  }
  goToconditionsCarriage(){
    this.router.navigate(['conditionsCarriage'])
  }
  goTosettingPage(){
    this.router.navigate(['setting'])
  }

  onSignOut(){
    this.modalService.showConfirmation();
  }

  onSupport(){
    this.router.navigate(['support']);
  }

  onNotification(){
    this.router.navigate(['notification']);
  }

  onChangePassowrd(){
    this.router.navigate(['change-password']);
  }

  onLanguageTranslate() {
    this.translate.use(this.currentLang);
    localStorage.setItem("jmc_lang", JSON.stringify(this.currentLang));
    this._commonService.setLang();
    this._commonService.currentLangChange(true);

  }

}
