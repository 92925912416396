import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-jmc-points',
  templateUrl: './jmc-points.component.html',
  styleUrls: ['./jmc-points.component.scss']
})
export class JmcPointsComponent implements OnInit {

  data: any;
  constructor( public translate: TranslateService,
  public dialog: MatDialogRef<JmcPointsComponent>) { }

  ngOnInit(): void {
  }

}
