import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { SetInterceptorService } from './services/set-interceptor/set-interceptor.service';
import { GetInterceptorService } from './services/get-interceptor/get-interceptor.service';
import { MainComponent } from './main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { BookingServiceComponent } from './modal/booking-service/booking-service.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { TermsConditionComponent } from './modal/terms-condition/terms-condition.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ConfirmationComponent } from './modal/confirmation/confirmation.component';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JmcPointsComponent } from './modal/jmc-points/jmc-points.component';
import { CancelBookingComponent } from './modal/cancel-booking/cancel-booking.component';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import { ApplicationInitializerFactory, HttpLoaderFactory } from './translation.config';
import { NgxUiLoaderModule,NgxUiLoaderConfig } from "ngx-ui-loader";

registerLocaleData(localeAr);
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
	"bgsColor": "#0D4B95",
	"pbColor": "#0D4B95",
	"fgsColor":"#0D4B95"
  }

@NgModule({
	declarations: [
		AppComponent,
		MainComponent,
		BookingServiceComponent,
		TermsConditionComponent,
		ConfirmationComponent,
		JmcPointsComponent,
		CancelBookingComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatCheckboxModule,
		MatDialogModule,
		FormsModule,
		MatInputModule,
		ReactiveFormsModule,
		MatRadioModule,
		NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
		ToastrModule.forRoot({
			maxOpened: 1
		}),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyCQlNav_9yaU5nBiHnmeeqFl63tI60KGh4'
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
		// TranslateModule.forRoot({
		// 	loader: {
		// 		provide: TranslateLoader,
		// 		useFactory: createTranslateLoader,
		// 		deps: [HttpClient]
		// 	}
		// })
	],
	providers: [
		// CommonService,
		{ provide: HTTP_INTERCEPTORS, useClass: SetInterceptorService, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: GetInterceptorService, multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: ApplicationInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
	],
	entryComponents: [
		BookingServiceComponent,
		TermsConditionComponent,
		ConfirmationComponent,
		JmcPointsComponent,
		CancelBookingComponent
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
