import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService} from '../../services/common.service';

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss']
})
export class CancelBookingComponent implements OnInit {

  constructor(public dialog: MatDialogRef<CancelBookingComponent>, 
  private toastr: ToastrService,
  public translate: TranslateService,
  private router: Router,
  private commonService: CommonService) { }

  ngOnInit(): void {
  }

  onSubmit(){
    this.dialog.close('yes');
  }
  
  onCancel(){
    this.dialog.close();
  } 

}
