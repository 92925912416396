import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NotificationSocketService } from '../services/socket/notification-socket.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../pages/notification/notification.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  url: any;
  isShow: boolean = false;
  user: any;
  homeStatus: boolean = false;
  footerStatus: boolean = false;
  showLogo: boolean = false;

  constructor(public router: Router,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private socketService: NotificationSocketService) { }

  ngOnInit(): void {

  }

  onActivate(e) {
    if (localStorage.getItem('jmc_web')) {
      this.user = JSON.parse(localStorage.getItem('jmc_web'))['user'];
    } else {
      this.user = '';
    }
    window.scroll(0, 0);
    this.url = this.router.url.split('?')[0];
    console.log("djjsjjd",this.router.url.split('/')[0]);
    
    console.log(this.url,"-=-===");
    
    if (this.url == '/signup/phone' || this.url == '/login/phone' || this.url == '/signup/email' || this.url == '/login/email' || this.url == '/forgot-password' || this.url == '/verification' || this.url == '/register-user' || this.url == '/reset-password') {
      this.isShow = false;
      this.showLogo = false;
    } else {
      this.isShow = true;
      if (this.url == '/home' || this.url == '/aboutUs' || this.url == '/ourServices' || this.url == '/contactUs') {
        this.homeStatus = true;
        this.showLogo = false;
      } else {
        this.homeStatus = false;
        this.showLogo = false;
      }
    }
    if (this.url == '/support' && (!this.user || (this.user && this.user['firstName'] == ''))) {
      this.homeStatus = true;
      this.showLogo = false;
    } else if (this.url == '/support' && this.user) {
      this.homeStatus = false;
      this.showLogo = false;
    }
    if (this.url == '/congratulation') {
      this.isShow = false;
      this.homeStatus = false;
      this.showLogo = false;
    } 

    // if (this.url == '/congratulation' || this.router.url.split('/')[1] =='pending-booking') {
    //   this.isShow = false;
    //   this.homeStatus = false;
    //   this.showLogo = false;
    // } 
    if (this.url == '/home' || this.url == '/aboutUs' || this.url == '/contactUs' || this.url == '/ourServices') {
      this.footerStatus = true;
    } else {
      this.footerStatus = false;
    }
    this.getNotificationStatus();
  }

  getNotificationStatus() {
    this.socketService.getNotificationStatus().subscribe(res => {
      console.log(res, "response")
      if (res) {
        this.playAudio();
        this.getCustomerNotification();
        this.socketService.setOption(res);
        this.toastrService.success(res['payload']['body']).onTap.subscribe(
          (func) => {
            if (res['payload']['type'] == 3) {
              this.router.navigate(['/voucher'], { queryParams: { type: 'list' } })
            } else {
              this.router.navigate(['/notification'])
            }
          });
      }
    });
  }

  playAudio() {
    const audio = new Audio();
    audio.src = 'assets/sounds/notification-sound1.mp3';
    audio.load();
    audio.play();
  }

  getCustomerNotification() {
    this.notificationService.getCustomerNotification().subscribe(res => {
      if (res['code'] == 200) {
        this.commonService.notificationList = res['data'];
      }
    })
  }
}
