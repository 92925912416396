import { Component, OnInit } from '@angular/core';
import { BookingService } from '../booking/booking.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pending-booking',
  templateUrl: './pending-booking.component.html',
  styleUrls: ['./pending-booking.component.scss']
})
export class PendingBookingComponent implements OnInit {

  message: string;
  successStatus: boolean = false;

  constructor(private bookingService: BookingService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.forEach((params: any) => {
      if(params['id']){
        this.getPaymentToken(params['id']);
      }
    })

    this.route.queryParams
      .subscribe(params => {
        if (params['type'] == 'success') {
          this.successStatus = true;
          this.message = "Your payment has been successfully processed."
        }
        if (params['type'] == 'failer') {
          this.successStatus = false;

          this.message = "Your payment has been declined."
        }
      })

  }

  getPaymentToken(id) {
    let data = {
      bookingId: id
    }
    this.bookingService.pendingPaymentToken(data).subscribe(res => {
      if (res['code'] == 200) {
        if(res['data'] && res['data']['order'] && res['data']['order']['url']){
          window.open(res['data']['order']['url'],"_self")
        }else{
          this.successStatus = true;
          this.message = "Your payment has been successfully processed."
        }
      }
    })
  }
}
