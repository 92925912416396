import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-booking-service',
  templateUrl: './booking-service.component.html',
  styleUrls: ['./booking-service.component.scss']
})
export class BookingServiceComponent implements OnInit {
  data:any;
  selectedSlot: any;

  constructor(public dialog: MatDialogRef<BookingServiceComponent>,
  private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  onSubmit(){
    if(this.selectedSlot){
       console.log(this.selectedSlot);
    }else{
      this.toastr.error("Please Select slot first");
    }
    console.log(this.selectedSlot)
  }

  onCancel(){
    this.dialog.close();
  }

}
