import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service.js';

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.scss']
})
export class Header2Component implements OnInit {
 
  currentLang:any;
  langArray = [
    {
      name: "EN",
      value: "en"
    }, {
      name: "AR",
      value: "ar"
    }
  ]

  constructor(
    public translate: TranslateService,
    private router: Router,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    if(localStorage.getItem('jmc_lang')){
      this.currentLang = JSON.parse(localStorage.getItem('jmc_lang'));
    }else{
      this.currentLang = 'en';
      localStorage.setItem("jmc_lang", JSON.stringify(this.currentLang));
      this._commonService.setLang();
    }
  }

  onSupport(){
    this.router.navigate(['support']);
  }

  onLanguageTranslate() {
    this.translate.use(this.currentLang);
    localStorage.setItem("jmc_lang", JSON.stringify(this.currentLang));
    this._commonService.setLang();
    this._commonService.currentLangChange(true);
  }

}
