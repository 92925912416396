import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UrlService {

  // baseUrl = "https://appgrowthcompany.com:3092/api/v1";
  // imageUrl = "https://appgrowthcompany.com:3092";
  // socketUrl = "https://appgrowthcompany.com:3092";
  baseUrl = "https://api.jmcbooking.com/api/v1";
  imageUrl = "https://api.jmcbooking.com";
  socketUrl = "https://api.jmcbooking.com";

  constructor() { }

  signup = `${this.baseUrl}/customer/signup`;
  setupProfile = `${this.baseUrl}/customer/setupProfile`;
  signin = `${this.baseUrl}/customer/signin`;
  sendOtp= `${this.baseUrl}/customer/sendOtp`;
  resetPassword = `${this.baseUrl}/customer/resetPassword`;
  verifyOtp= `${this.baseUrl}/customer/verifyOtp`;
  getServices= `${this.baseUrl}/customer/getServices`;
  airportAirline= `${this.baseUrl}/customer/airportAirline`;
  serviceSlot= `${this.baseUrl}/customer/serviceSlot`;
  uploadLuggaage= `${this.baseUrl}/customer/uploadLuggaage`;
  getBookingCost= `${this.baseUrl}/customer/getBookingCost`;
  getDiscounts= `${this.baseUrl}/customer/getDiscount`;
  createBooking= `${this.baseUrl}/customer/createBooking`;
  paymentToken= `${this.baseUrl}/customer/paymentToken`;
  getBooking= `${this.baseUrl}/customer/getBooking`;
  getBookingAll= `${this.baseUrl}/customer/getBookingAll`;
  addReview= `${this.baseUrl}/customer/addReview`;
  updatePassword= `${this.baseUrl}/customer/updatePassword`;
  getRedeem= `${this.baseUrl}/customer/getRedeem`;
  getBookingByCart= `${this.baseUrl}/customer/getBookingByCart`;
  logout= `${this.baseUrl}/customer/logout`;
  getCustomerNotification= `${this.baseUrl}/customer/getCustomerNotification`;
  getAdditional= `${this.baseUrl}/customer/getAdditional`;
  cms=`${this.baseUrl}/admin/getCms`;
  cancelBooking=`${this.baseUrl}/customer/cancelBooking`;
  updateLang=`${this.baseUrl}/customer/updateLang`;
  pendingPaymentToken=`${this.baseUrl}/customer/pendingPaymentToken`;
}

