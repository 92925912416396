<div class="heding_innpages navbar navbar-expand-md bg-dark navbar-dark" >
    <div class="heding_innpages_main">
        <div class="logo_div"><div class="logo_div_inner" (click)="goToHomepage()"><figure><img src="assets/images/colored_logo.svg" alt="Logo"></figure></div></div>
        <div class="heding_innpagesrgt_div">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#inner_headernav">
                <span class="navbar-toggler-icon"></span>
            </button>
            <ul class="d-flex">
                <li class="lng_inn_dv">
                    <div class="lng_mnu">
                        <select [(ngModel)]="currentLang" class="lng_dv_inn" (ngModelChange)="onLanguageTranslate()">
                            <option *ngFor="let lang of translate.getLangs()" [value]="lang">
                                <ng-container *ngFor="let customName of langArray">
                                    <ng-container *ngIf="customName.value == lang">
                                        {{customName.name}}
                                    </ng-container>
                                </ng-container>
                            </option>
                        </select>
                    </div>
                </li>
                <li class="dropdown pfl_dv" *ngIf="logedInUser">  
                    <a type="button" class="profile_dv dropdown-toggle" data-toggle="dropdown">
                        <span style="width: 45px;height: 45px;"><img [src]="logedInUser && logedInUser['profilePic'] ? _urlService.imageUrl + logedInUser.profilePic : 'assets/images/user_pic.png'" alt="User Pic"></span>
                    </a>
                    <div class="dropdown-menu">
                        <ul>
                            <li><a class="dropdown-item" (click)="goToprofileMainPage()">{{'PROFILE'|translate}}</a></li>
                            <li><a class="dropdown-item" (click)="onChangePassowrd()">{{'CHANGE PASSWORD'|translate}}</a></li>
                            <li><a class="dropdown-item" (click)="onNotification()">{{'NOTIFICATION'|translate}}</a></li>
                            <li><a class="dropdown-item" (click)="goTosettingPage()">{{'SETTINGS'|translate}}</a></li>
                            <li class="logged_out"><a class="dropdown-item" (click)="onSignOut()">{{'SIGN OUT'|translate}}</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="lgin_rgt bking_rgt">
        <div class="collapse navbar-collapse" id="inner_headernav">
            <div class="lng_dv innr_hdr_sc">
                <ul class="d-flex">
                    <li class="inn_menu inn_menu_jmcpoints"  *ngIf="(logedInUser && logedInUser['firstName'])"><a (click)="goTojmcPoints()">{{'JMC POINTS'|translate}}</a></li>
                    <li class="inn_menu inn_menu_discount" *ngIf="(logedInUser && logedInUser['firstName'])"><a (click)="goTodiscountVoucher()">{{'DISCOUNT VOUCHERS'|translate}}</a></li>
                    <li class="inn_menu inn_menu_bkhistry" *ngIf="(logedInUser && logedInUser['firstName'])"><a (click)="goTobookingHistory()">{{'BOOKING HISTORY'|translate}}</a></li>
                    <li class="inn_menu inn_menu_spprt"><a (click)="onSupport()">{{'SUPPORT'|translate}}</a></li>
                    <li class="lng_inn_dv">
                        <div class="lng_mnu">
                            <select [(ngModel)]="currentLang" class="lng_dv_inn" (ngModelChange)="onLanguageTranslate()">
                                <option *ngFor="let lang of translate.getLangs()" [value]="lang">
                                    <ng-container *ngFor="let customName of langArray">
                                        <ng-container *ngIf="customName.value == lang">
                                            {{customName.name}}
                                        </ng-container>
                                    </ng-container>
                                </option>
                            </select>
                        </div>
                    </li>
                    <li class="dropdown pfl_dv" *ngIf="logedInUser">  
                        <a type="button" class="profile_dv dropdown-toggle" data-toggle="dropdown">
                            <span style="width: 45px;height: 45px;"><img [src]="logedInUser && logedInUser['profilePic'] ? _urlService.imageUrl + logedInUser.profilePic : 'assets/images/user_pic.png'" alt="User Pic"></span>
                        </a>
                        <div class="dropdown-menu">
                            <ul>
                                <li><a class="dropdown-item" (click)="goToprofileMainPage()">{{'PROFILE'|translate}}</a></li>
                                <li><a class="dropdown-item" (click)="onChangePassowrd()">{{'CHANGE PASSWORD'|translate}}</a></li>
                                <li><a class="dropdown-item" (click)="onNotification()">{{'NOTIFICATION'|translate}}</a></li>
                                <li><a class="dropdown-item" (click)="goTosettingPage()">{{'SETTINGS'|translate}}</a></li>
                                <li class="logged_out"><a class="dropdown-item" (click)="onSignOut()">{{'SIGN OUT'|translate}}</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>            
            </div>
        </div>
    </div>
</div>

