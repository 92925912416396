import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { PendingBookingComponent } from 'src/app/pages/pending-booking/pending-booking.component';

const routes: Routes = [
  {
      path:'',
      redirectTo: 'home',
      pathMatch: 'full'
  },
  {   
    path: '', component: MainComponent,
    children: [
      {
        path: '',  
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: '',  
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      },{
        path: 'sms/:id',
        component: PendingBookingComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
